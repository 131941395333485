.logo-holder,
.login-form-logo-holder {
  margin: 15px;
  padding-left: 20px;
  padding-right: 20px;
}

.logo-holder #logo-image,
.login-form-logo-holder #logo-image {
  height: 50%;
  width: 75%;
}

.middle-section-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 10px;
  color: #fafafa;
}

.middle-section-container .response-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fafafa;
  font-size: 22px;
}

.middle-section-container .response-container h4 {
  padding-top: 15px;
}

.middle-section-container .response-container #error-icon {
  margin: 7px;
  color: #dc3545;
}

.middle-section-container .response-container #success-icon {
  margin: 7px;
  color: #097b09;
}

.middle-section-container button {
  margin-top: 25px;
  padding: 10px;
}

ul {
  list-style-type: none;
}

.add-devices-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 100;
  align-items: center;
}
