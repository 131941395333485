.toast-notify-body {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: stretch;
  border-width: 2px;
  border-style: solid;
  border-radius: 10px;
  padding: 10px;
}

.toast-notify-text {
  width: 95%;
}

.toast-notify-buttons-wrapper {
  display: flex;
  flex-direction: column;
  width: 5%;
  align-items: flex-end;
}

@media only screen and (max-width: 600px) {
  .toast-notify-body {
    font-size: 11px;
  }
}
