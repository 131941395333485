.toggle-switch-box {
  font-size: 20px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  font-family: 'Blender Pro';
  align-items: center;
}

.select-input-box {
  border-bottom: 1px solid #ced4da;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  background-color: pink;
}

.testing {
  background: green;
  color: pink;
  font-size: 28px;
}

/* Custom styles for the Bootstrap switch when it is active (checked) */
/* Adjust the background and border color of the switch when active */
.custom-switch .form-check-input:checked ~ .custom-control-label::before {
  background-color: green !important;
  border-color: green !important;
}

/* Adjust the size of the switch */
.custom-switch .custom-control-label::before {
  width: 40px !important; /* Width of the switch */
  height: 20px !important; /* Height of the switch */
}

.custom-switch .custom-control-label::after {
  width: 20px !important; /* Width of the circle inside the switch */
  height: 20px !important; /* Height of the circle, should match .custom-control-label::before height */
}

.form-switch .form-check-input:checked {
  background-color: green;
  border-color: green;
}

.form-switch .form-check-input {
  bottom: 1.5px;
}

.emergency-picker-style {
  background-color: #161816;
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 0 12px;
  border: 1px solid #161816;
}

select.testing {
  -webkit-appearance: none; /* Removes the default Safari styling */
  -moz-appearance: none;    /* Removes Firefox default styling, if needed */
  appearance: none;         /* Standard appearance property */
  outline: none;
  box-shadow: none;
}
