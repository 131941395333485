body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html {
  height: 100%;
  /*position: fixed;*/
}

body {
  height: 100%;
  font-family: 'Blender Pro', serif;
  background: #1c1c1e;
  position: fixed;
  --regular-text: 16px;
  --big-text: 1.25rem;
  --small-text: 0.812rem;
  --tiny-text: 0.625rem;
  --small-header: 1.562rem;
  --header: 1.938rem;
  --golden-color: rgba(214, 161, 87, 1);
  --golden-border-color: #b77c2c;
  --whithe-color: #fafafa;
  --input-and-buttons-height: 58px;
}

#root {
  height: 100%;
  line-height: 1.2;
  font-size: 16px;
  font-family: 'Blender Pro', serif;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100vw;
  background: #1c1c1e;
  //background: #222629;
  /*background: #333333; this is for the back theme */

  -webkit-overflow-scrolling: touch;
}

.spinner-grow {
  color: #d7a157 !important;
}

.gold {
  color: #d7a157 !important;
}

.gold-button-variant {
  border-radius: 4px;
  width: 100%;
  background-color: #d6a157;
  color: black !important;
  border-width: 0.4px;
  border-color: transparent !important;
  font-size: 21px;
  line-height: 21px;
  padding: 14px 0px;
  font-family: 'Blender Pro Bold';
  text-transform: uppercase;
}

.gold-button-variant:disabled {
  background-color: #d6a157;
  color: black;
  border-color: transparent !important;
}

.gold-button-variant:active {
  background-color: #d6a157 !important;
  border-color: transparent !important;
}

.gold-button-variant:focus {
  background-color: #d6a157 !important;
  color: black;
  border-color: transparent !important;
}

.gold-button-variant:hover {
  background-color: #d6a157 !important;
  color: black;
  border-color: transparent !important;
}
.black-button-variant {
  background-color: #2d312d !important;
  border-radius: 4px;
  padding: 9px;
  width: 100%;
  color: #ffffff;
  border-width: 0.4px;
  border-color: transparent !important;
  margin-top: 20px;
}

.green-button-variant {
  border-radius: 10px;
  padding: 9px;
  width: 100%;
  border-width: 0.4px;
  border-color: transparent !important;
  margin-top: 5px;
  font-weight: 600;
}

.red-button-variant {
  border-radius: 10px;
  padding: 9px;
  width: 100%;
  background-color: #ee4547 !important;
  color: white !important;
  border-width: 0.4px;
  border-color: transparent !important;
  margin-top: 5px;
  font-weight: 600;
}

.basic-button-variant {
  border-radius: 6px;
  padding: 7px;
  width: 100%;
  border-width: 0.4px;
  border-color: #ced4da !important;
  margin-top: 5px;
  font-weight: 600;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #909;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: red;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: red;
}

.form-input {
  background-color: #161816 !important;
  border-radius: 6px;
  border: 1px solid #2d312d;
  color: #656565;
  height: calc(1.5em + 0.75rem + 6px);
  text-align: left;
}

.form-input:focus {
  color: #c2bdbd;
  border-color: var(--golden-color);
  outline: none !important;
  box-shadow: none;
}
body .form-select {
  background-color: #161816 !important;
  border-radius: 10px;
  border: 1px solid #2d312d;
  color: var(--whithe-color);
  font-size: var(--big-text);
  height: calc(1.5em + 0.75rem + 6px);
  text-align: left;
}

body .form-select:focus {
  color: #c2bdbd;
  border-color: var(--golden-color);
  outline: none !important;
  box-shadow: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  background-color: #f66e62;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #161816 inset !important;
}

.verify-сode-text {
  text-decoration: underline;
  line-height: 20px;
  font-weight: bolder;
  font-size: 20px;
  font-family: 'Blender Pro Bold';
  color: #fafafa;
}

::placeholder {
  text-align: center;
}

#content {
  /*!*height: 90%;*!*/
  /*flex: 9 1 0;*/
  /*color: #fff;*/
  /*overflow-y: auto;*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

#navbar {
  /*height: 10%;*/
  flex: 1 1;
  background: #3a3f42;
}

#error-symbol {
  font-size: 50px;
  border: 1px solid;
  border-radius: 50%;
  height: 55px;
  width: 55px;
  margin: auto;
}

.internet-error {
  display: flex;
  justify-content: space-between;
  height: 35px;
  width: 100%;
  background: #f66e62;
  margin-top: 0;
  font-size: 16px;
}

.internet-error p {
  font-size: 16px;
  line-height: 35px;
  color: #fff;
  margin-left: 6%;
}

.container-settings-form {
  color: white;
  border-radius: 10px;
  border-width: 2px;
  border-color: #161816;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;
}

.container-form {
  color: white;
  flex: 1 1;
  border-radius: 10px;
  border-width: 2px;
  border-color: #161816;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-self: center;
}

.notification-container {
  flex: 1 1;
}

.input-container.reset-margin {
  margin-bottom: 0; /* Example: Removing bottom margin */
  padding: 15px; /* Adding padding */
  background-color: #f8f9fa; /* Light background */
}

body .form-control {
  background-color: #131313;
  border: 1px solid #131313;
  border-radius: 10px;
  color: #fafafa;
  font-size: var(--big-text);
  height: var(--input-and-buttons-height);
}

body .form-control:disabled {
  border: 1px solid #131313;
  opacity: 0.4;
}

body .form-control:focus {
  background-color: #131313;
  color: #fafafa;
  box-shadow: 0 0 0 0.1px #d7a157;
  border-color: var(--golden-color);
}

.form-floating > .form-control ~ label {
  color: #979997 !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.form-floating > .form-control:focus ~ label {
  color: #979997 !important;
}

.form-floating {
  height: var(--input-and-buttons-height);
}

.form-floating > input + label::after {
  background: transparent !important;
  color: red !important;
}

.floating-label {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
}

.form-floating > .form-select ~ label::after {
  background: transparent !important;
}

.form-floating > .form-select ~ label {
  color: #979997 !important;
  font-family: 'Blender Pro';
  font-size: var(--regular-text);
}

.select {
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
    no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
}

@media only screen and (max-width: 600px) {
  .internet-error p {
    font-size: 14px;
  }
}

.internet-error div {
  color: #fff;
  margin-right: 8%;
  margin-top: 4px;
  animation: spin 1000ms infinite linear;
}

.toast-notification {
  position: absolute !important;
  margin-bottom: 10px !important;
  margin-top: 40px !important;
  width: 470px !important;
}
.toast-notification span {
  width: 100% !important;
  padding: 0px 0px !important;
  border-radius: 10px !important;
}

/* Remove Arrows: Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove Arrows: Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@font-face {
  font-family: 'metropolisbold';
  src:
    url(/static/media/metropolis-bold-webfont.bb140be0.woff2) format('woff2'),
    url(/static/media/metropolis-bold-webfont.d9ba59fb.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'brandonbold';
  src:
    url(/static/media/brandon_bld.74790917.woff) format('woff'),
    url(/static/media/brandon_bld.415001eb.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'brandonregular';
  src:
    url(/static/media/brandon_med.faa34691.woff) format('woff'),
    url(/static/media/brandon_med.e26ad7ce.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'metropolisregular';
  src:
    url(/static/media/metropolis-regular-webfont.79d9bbc5.woff2) format('woff2'),
    url(/static/media/metropolis-regular-webfont.960448c7.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Blender Pro';
  src: url(/static/media/BlenderPro-Medium.c4aa2eb1.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Blender Pro Heavy';
  src: url(/static/media/BlenderPro-Heavy.ba7e81b1.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Blender Pro Bold';
  src: url(/static/media/BlenderPro-Bold.a855b392.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}

.hr-tag {
  width: 30%;
  align-self: flex-start;
  border: 1px solid #fafafa;
}

.success-button {
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  padding: 8px;
}

.settings-option-text {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  font-size: 25px;
  font-weight: 400;
}

.clickable-link {
  color: #d7a157;
  font-size: var(--big-text);
  text-decoration: none;
}

.clickable-link:hover {
  color: #d7a157;
  text-decoration: none;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.reset-margin {
  margin: 0px;
}

.reset-padding {
  padding: 0px;
}

.uppercase {
  text-transform: uppercase;
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  padding: 25px;
  box-sizing: border-box;
  width: 470px;
  background-color: rgba(34, 38, 41, 1);
  border-radius: 10px;
}

body .primary-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: var(--input-and-buttons-height);
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  padding: 14px;
  border: 1.5px solid var(--golden-border-color);
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #d7a157 !important;
  font-family: 'Blender Pro Bold';
  font-size: var(--small-header);
  text-transform: uppercase;
  color: #0c0c0d;
  margin-top: 0 !important;

}

.logo-main {
  maxWidth: 470;
  width: 100%;
}

body .primary-button:hover {
  background-color: #d6a157;
  color: black;
  border: 1.5px solid var(--golden-border-color);

}

body .primary-button:disabled {
  font-family: 'Blender Pro Bold';
  color: #0c0c0d;
}

body .primary-button:active {
  border: none;
  color: #0c0c0d !important;
}

body .primary-button:focus {
  box-shadow: none;
  border: none;
}

body .edit-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: var(--input-and-buttons-height);
  grid-gap: 10px;
  gap: 10px;
  border: 1.5px solid var(--golden-border-color);
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #d7a157 !important;
  font-family: 'Blender Pro Bold';
  font-size: var(--big-text);
  text-transform: uppercase;
  color: #0c0c0d;
  margin-left: 15px;
}

body .edit-button:hover {
  background-color: #d6a157;
  color: black;
  border-color: transparent;
}

body .edit-button:disabled {
  font-family: 'Blender Pro Bold';
  color: #0c0c0d;
}

body .back-button-header-container {
  display: flex;
  flex: 1 1;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}

.back-button-header-title {
  font-size: var(--header);
  color: var(--golden-color);
  text-transform: uppercase;
}

body .regular-text {
  color: var(--whithe-color);
  font-size: var(--regular-text);
  margin: 0;
  text-align: left;
  padding-left: 5px;
}

body .small-text {
  font-size: var(--small-text);
  color: #6c757d !important;
  margin: 0;
}

body .standart-bottom-padding {
  margin-bottom: 20px;
}

body .screen {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.gapper {
  grid-gap: 20px;
  gap: 20px;
}

.no-gap {
  grid-gap: 0;
  gap: 0;
}

.floating-label-custom {
  flex: 1 1;
  font-size: 1.2rem;
}

body .default-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-gap: 20px;
  gap: 20px;
}

body .red-variant {
  background-color: #ee4547 !important;
  border: none;
  color: var(--whithe-color);
}

body .red-variant:hover {
  background-color: #ee4547 !important;
  border: none;
  color: var(--whithe-color);
}

body .transparent-variant {
  background-color: transparent !important;
  border: 1px solid #979997;
  color: var(--whithe-color);
}

body .default-margin-top {
  margin-top: 20px;
}

.regular-form {
  display: flex; flex-direction: column; flex: 1 1; width: 100%; grid-gap: 20px; gap: 20px;
}

@supports (-webkit-touch-callout: none) {
  .form-floating > .form-control ~ label::after {
    content: none;
  }

  .form-floating > .form-control:focus ~ label::after,
  .form-floating > .form-control:not(:placeholder-shown) ~ label::after {
    content: ''; /* Adjust content or styles as needed */
  }
}

.floating-label-custom label {
  transform: scale(1) translateY(0); /* Ensures initial position */
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label {
  transform: scale(0.7) translateY(-0.5rem) translateX(0.15rem) !important;
}

@supports (-webkit-touch-callout: none) {
  /* Targeting Safari specifically */

  .floating-label-custom label::after {
    display: none; /* Hides ::after by default in Safari */
  }

  .form-floating > .form-control:focus ~ label::after,
  .form-floating > .form-control:not(:placeholder-shown) ~ label::after {
    display: inline; /* Only show ::after when focused or has value */
  }
}

/* Default state */
.form-floating > .form-control ~ label {
  transform: scale(1) translateY(0) !important; /* Keeps label in place by default */
}

select.testing:focus {
  outline: none;
  box-shadow: none;
  -webkit-appearance: none !important; /* Ensures consistent appearance across browsers */
}

.settings_button_icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}


@media only screen and (max-width: 768px) {

  .container-settings-form, .container-form, .notification-container, .main-container {
    max-width: 90%;
    width: 470px;
  }

  .gold-button-variant, .black-button-variant, .green-button-variant, .red-button-variant, .basic-button-variant {
    font-size: 18px;
    padding: 10px;
  }

  .settings_button_icon {
    width: 28px;
    height: 28px;
  }

  .back-button-header-title {
    font-size: 1.5rem;
  }

  .primary-button, .edit-button {
    height: 50px;
  }
}

@media only screen and (max-width: 600px) {

  body {
    --regular-text: 14px;
    --big-text: 1rem;
    --small-header: 1.25rem;
    --header: 1.5rem;
    --input-and-buttons-height: 50px;
    background-color: red;
  }
  logo-main {
    max-width: 100%;
  }

  .container-settings-form, .container-form, .notification-container, .main-container {
    min-width: 350px;
    width: 90%;
    padding: 10px;

  }

  .gold-button-variant, .black-button-variant, .green-button-variant, .red-button-variant, .basic-button-variant {
    padding: 8px;
    font-size: 16px;
  }

  .settings_button_icon {
    width: 24px;
    height: 24px;
  }

  .internet-error p {
    font-size: 14px;
  }

  .primary-button, .edit-button {
    height: var(--input-and-buttons-height);
    font-size: var(--regular-text);
  }

  .back-button-header-title {
    font-size: var(--small-header);
  }

  .hr-tag {
    width: 50%;
  }

  .toast-notification {
    width: 90% !important;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 480px) {

  .container-settings-form, .container-form, .notification-container, .main-container {
    min-width: 350px;
    width: 90%;
    margin: 10px;
  }

  logo-main {
    width: 10px;
  }

  .gold-button-variant, .black-button-variant, .green-button-variant, .red-button-variant, .basic-button-variant {
    padding: 6px;
    font-size: 14px;
  }

  .settings_button_icon {
    width: 20px;
    height: 20px;
  }

  .primary-button, .edit-button {
    font-size: var(--small-header) !important;
  }

}

.settings-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fafafa;
}

.settings-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-gap: 15px;
  gap: 15px;
}

.settings-page .header {
  color: white;
  font-size: 2em;
  font-weight: bold;
  background-color: #4d5359;
}

.settings-page_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

.settings-page_body {
  width: 100%;
  padding-top: 15px;
  background-color: #222629;
  border-width: 1px;
  border-color: rgba(204, 202, 200, 0.3);
  border-style: solid;
}

.settings-page_header {
  font-size: 2em;
  color: #d7a157;
}

.settings-page_subpage-header {
  font-size: 2em;
  color: #d7a157;
}

.settings-page .info-container {
}

.settings-page .setup-new-device {
  border: 2px solid black;
  border-radius: 4px;
}

.settings-page .button-controls {
  padding: 10px 10px 25px 10px;
}

.settings-page .render-container {
  display: flex;
  flex: 1 1;
  width: 100%;
}

.settings-page .back-to-all-settings-button {
  display: flex;
  justify-content: center;
}

.settings-page .back-to-all-settings-button .btn {
  width: 50%;
}

.settings-page .form-container {
  /*padding: 15px 15px 15px 15px;*/
  width: 100%;
}

.settings-page .log-out-button-container {
}

.settings-page .header-container {
  display: flex;
  background: #4d5359;
  height: 6vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

.settings-page .header-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

.settings-page .back {
  width: 25px;
  color: #d7a157;
  font-size: 22px;
  background: #222629;
  padding: 5px 5px 5px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.settings_page_buttons {
  border-radius: 10px;
  padding: 0 !important;
  color: #f9f9f9 !important;
  border: 1px solid #2f3237 !important;
  font-weight: 600;
  box-shadow: none;
  display: flex;
  font-family: 'Blender Pro Bold', serif;
  background-color: #2f3237 !important;
  text-decoration: none;
  width: 100%;
}

.settings_page_buttons:hover {
  background-color: #2f3237 !important;
  border: 1px solid #2f3237 !important;
  text-decoration: none;
}

.settings-page .back span {
  margin-left: 5px;
}

.component-header {
  width: 80%;
  font-size: 1.6em;
}

input::placeholder {
  color: #c1c2c3 !important;
}

.form-group {
  color: white;
  text-align: left;
}

body .settings-option-container {
  padding: 13px 15px;
  display: flex;
  flex: 1 1;
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-start;
  background-color: #2f3237;
  border-radius: 6px;
  align-items: center;
}


@media (min-width: 500px) {
  .buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .setting-form {
    text-align: -moz-center;
    text-align: -webkit-center;
  }
  .component-header {
    font-size: 2em;
  }
}

.slide-left-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.slide-left-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

.slide-left-exit {
  opacity: 1;
  transform: translateX(0%);
}

.slide-left-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

.slide-right-enter {
  opacity: 0;
  transform: translateX(100%);
}

.slide-right-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

.slide-right-exit {
  opacity: 1;
  transform: translateX(0%);
}

.slide-right-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

.transition-group {
  position: relative;
}

.route-section {
  position: absolute;
  width: 100%;
}

.change-profile_edit-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 10px 15px;
  background-color: #d6a157;
  color: black !important;
  border-width: 0.4px;
  border-color: transparent !important;
  font-weight: 600;
  margin-left: 15px;
  margin-bottom: 15px;
}

.change-profile_edit-button:active {
  background-color: #d6a157 !important;
  border-color: transparent !important;
}

.change-profile_edit-button:focus {
  background-color: #d6a157 !important;
  border-color: transparent !important;
}

.change-profile_edit-button:hover {
  background-color: #d6a157 !important;
  border-color: transparent !important;
}

.change-profile_row {
  width: 100%;

  //margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.change-profile_text {
  margin-bottom: 15px;
  text-align: left;
}

.toast-notify-body {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: stretch;
  border-width: 2px;
  border-style: solid;
  border-radius: 10px;
  padding: 10px;
}

.toast-notify-text {
  width: 95%;
}

.toast-notify-buttons-wrapper {
  display: flex;
  flex-direction: column;
  width: 5%;
  align-items: flex-end;
}

@media only screen and (max-width: 600px) {
  .toast-notify-body {
    font-size: 11px;
  }
}

.spinner-container {
  height: 100%;
  width: 100vw;
  background: #292929;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.spinner-and-text-holder {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.text-container {
  color: white;
  font-size: 35px;
  font-weight: 600;
}

.download_page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
}

.download_page-button {
  margin-bottom: 15px;
}

.delete_page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delete_page-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.container_text-box {
  padding-top: 15px;
  padding-right: 15px;
  padding-left: 15px;
  text-align: left;
}

.delete_page-button {
  margin-bottom: 15px;
}

.toggle-switch-box {
  font-size: 20px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  font-family: 'Blender Pro';
  align-items: center;
}

.select-input-box {
  border-bottom: 1px solid #ced4da;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  background-color: pink;
}

.testing {
  background: green;
  color: pink;
  font-size: 28px;
}

/* Custom styles for the Bootstrap switch when it is active (checked) */
/* Adjust the background and border color of the switch when active */
.custom-switch .form-check-input:checked ~ .custom-control-label::before {
  background-color: green !important;
  border-color: green !important;
}

/* Adjust the size of the switch */
.custom-switch .custom-control-label::before {
  width: 40px !important; /* Width of the switch */
  height: 20px !important; /* Height of the switch */
}

.custom-switch .custom-control-label::after {
  width: 20px !important; /* Width of the circle inside the switch */
  height: 20px !important; /* Height of the circle, should match .custom-control-label::before height */
}

.form-switch .form-check-input:checked {
  background-color: green;
  border-color: green;
}

.form-switch .form-check-input {
  bottom: 1.5px;
}

.emergency-picker-style {
  background-color: #161816;
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 0 12px;
  border: 1px solid #161816;
}

select.testing {
  -webkit-appearance: none; /* Removes the default Safari styling */    /* Removes Firefox default styling, if needed */
  appearance: none;         /* Standard appearance property */
  outline: none;
  box-shadow: none;
}

.reset-password {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

.reset-password_container {
  margin-top: 20px;
  background-color: #222629;
  border: 1px #0c0c0d solid;
}

.reset-password_header,
.reset-password_body {
  height: 100%;
  color: #fff;
  padding-right: 10px;
  padding-left: 10px;
}

.reset-password_header {
  background: #4d5359;
}

.reset-password_header-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 6vh;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
}

.reset-password_body-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.login-body {
  height: 100%;
  color: #fff;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
}

.login-form-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-container {
  max-width: 470px;
  background-color: #222629;
  border-radius: 10px;
  border: 1px #0c0c0d solid;
}

.forgot-password-buttons-container {
  width: 100%;
}

.header-container {
  display: flex;
  background: #4d5359;
  height: 6vh;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

.back {
  width: 25px;
  color: #d7a157;
  font-size: 22px;
  padding: 5px 5px 5px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.back-button-login-page .svg-inline--fa {
  margin-bottom: -1px;
  margin-right: 4px;
}

.signup-invite {
  color: #fff;
}

.reset-password_form-text {
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
}

@media (min-width: 500px) {
  .container-body-form {
    width: 100% !important;
  }

  .content-container {
    //width: 40%;
  }

  .reset-password,
  .login-form {
    text-align: -moz-center;
    text-align: -webkit-center;
  }

  .login-form-logo-holder img {
    width: 70% !important;
  }
}

.userOnBoarding_container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  height: 100%;
}

.userOnBoarding_body-container {
  max-width: 470px;
  padding: 0 25px 20px 25px;
  background-color: #222629;
  border-radius: 10px;
  border: 1px #0c0c0d solid;
  /*min-height: 90%;*/
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.userOnBoarding_logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
}

.smartbanner-show.smartbanner-margin-top {
  margin-top: 80px;
}

.smartbanner-show.smartbanner-margin-bottom {
  margin-bottom: 80px;
}

.smartbanner-show .smartbanner {
  display: block;
}

.smartbanner {
  left: 0;
  display: none;
  width: 100%;
  height: 80px;
  line-height: 80px;
  font-family:
    Helvetica Neue,
    sans-serif;
  background: #f4f4f4;
  z-index: 9998;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  -webkit-text-size-adjust: none;
}

.smartbanner-top {
  position: absolute;
  top: 0;
}

.smartbanner-bottom {
  position: fixed;
  bottom: 0;
}

.smartbanner-container {
  margin: 0 auto;
  padding: 0 5px;
}

.smartbanner-close {
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px 0 0;
  font-family: ArialRoundedMTBold, Arial;
  font-size: 20px;
  text-align: center;
  color: #888;
  text-decoration: none;
  border: 0;
  border-radius: 14px;
  padding: 0 0 1px;
  background-color: transparent;
  -webkit-font-smoothing: subpixel-antialiased;
}

.smartbanner-close:active,
.smartbanner-close:hover {
  color: #aaa;
}

.smartbanner-icon {
  width: 57px;
  height: 57px;
  margin-right: 12px;
  background-size: cover;
  border-radius: 10px;
}

.smartbanner-icon,
.smartbanner-info {
  display: inline-block;
  vertical-align: middle;
}

.smartbanner-info {
  white-space: normal;
  width: calc(99% - 201px);
  font-size: 11px;
  line-height: 1.2em;
  font-weight: 700;
}

.smartbanner-wrapper {
  max-width: 110px;
  display: inline-block;
  text-align: right;
  width: 100%;
}

.smartbanner-title {
  font-size: 13px;
  line-height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.smartbanner-description {
  max-height: 40px;
  overflow: hidden;
}

.smartbanner-author {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.smartbanner-author:empty + .smartbanner-description {
  max-height: 50px;
}

.smartbanner-button {
  margin: auto 0;
  height: 24px;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  font-weight: 700;
  color: #6a6a6a;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  /*text-shadow: 0 1px 0 hsla(0, 0%, 100%, .8)*/
}

.smartbanner-button:active,
.smartbanner-button:hover {
  color: #aaa;
}

.smartbanner-ios {
  background: black;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  line-height: 80px;
}

.smartbanner-ios .smartbanner-close {
  border: 0;
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-family: Arial;
  color: #888;
  /*text-shadow: 0 1px 0 #fff;*/
  -webkit-font-smoothing: none;
}

.smartbanner-ios .smartbanner-close:active,
.smartbanner-ios .smartbanner-close:hover {
  color: #fafafa;
}

.smartbanner-ios .smartbanner-icon {
  background-size: cover;
}

.smartbanner-ios .smartbanner-info {
  color: white;
  /*text-shadow: 0 1px 0 hsla(0, 0%, 100%, .8);*/
  font-weight: 300;
}

.smartbanner-ios .smartbanner-title {
  color: #fafafa;
  font-weight: 500;
}

.smartbanner-ios .smartbanner-button {
  padding: 0 10px;
  font-size: 15px;
  min-width: 10%;
  font-weight: 400;
  color: #d7a157;
}

.smartbanner-ios .smartbanner-button:active,
.smartbanner-ios .smartbanner-button:hover {
  background: #f2f2f2;
}

.smartbanner-android {
  /*background: #3d3d3d url("data:image/gif;base64,R0lGODlhCAAIAIABAFVVVf///yH5BAEHAAEALAAAAAAIAAgAAAINRG4XudroGJBRsYcxKAA7");*/
  background: black;
  /*box-shadow: inset 0 4px 0 #88b131;*/
  line-height: 82px;
}

.smartbanner-android .smartbanner-close {
  border: 0;
  max-width: 17px;
  width: 100%;
  height: 17px;
  line-height: 17px;
  margin-right: 7px;
  color: #b1b1b3;
  background: #1c1e21;
  text-shadow: 0 1px 1px #000;
  text-decoration: none;
  box-shadow:
    inset 0 1px 2px rgba(0, 0, 0, 0.8),
    0 1px 1px hsla(0, 0%, 100%, 0.3);
  cursor: pointer;
}

.smartbanner-android .smartbanner-close:active,
.smartbanner-android .smartbanner-close:hover {
  color: #eee;
}

.smartbanner-android .smartbanner-icon {
  background-color: transparent;
  box-shadow: none;
}

.smartbanner-android .smartbanner-info {
  color: #ccc;
  text-shadow: 0 1px 2px #000;
}

.smartbanner-android .smartbanner-title {
  color: #fff;
  font-weight: 700;
}

.smartbanner-android .smartbanner-button {
  min-width: 12%;
  color: #d1d1d1;
  font-weight: 700;
  padding: 0;
  background: none;
  border-radius: 0;
  box-shadow:
    0 0 0 1px #333,
    0 0 0 2px #dddcdc;
}

.smartbanner-android .smartbanner-button:active,
.smartbanner-android .smartbanner-button:hover {
  background: none;
}

.smartbanner-android .smartbanner-button-text {
  text-align: center;
  display: block;
  padding: 0 10px;
  background: #d7a157;
  /*background: linear-gradient(180deg, #42b6c9, #39a9bb);*/
  text-transform: none;
  text-shadow: none;
  box-shadow: none;
}

.smartbanner-android .smartbanner-button-text:active,
.smartbanner-android .smartbanner-button-text:hover {
  background: #d7a157;
}

.smartbanner-kindle,
.smartbanner-windows {
  background: #f4f4f4;
  background: linear-gradient(180deg, #f4f4f4, #cdcdcd);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  line-height: 80px;
}

.smartbanner-kindle .smartbanner-close,
.smartbanner-windows .smartbanner-close {
  border: 0;
  width: 18px;
  height: 18px;
  line-height: 18px;
  color: #888;
  text-shadow: 0 1px 0 #fff;
}

.smartbanner-kindle .smartbanner-close:active,
.smartbanner-kindle .smartbanner-close:hover,
.smartbanner-windows .smartbanner-close:active,
.smartbanner-windows .smartbanner-close:hover {
  color: #aaa;
}

.smartbanner-kindle .smartbanner-icon,
.smartbanner-windows .smartbanner-icon {
  background: rgba(0, 0, 0, 0.6);
  background-size: cover;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.smartbanner-kindle .smartbanner-info,
.smartbanner-windows .smartbanner-info {
  color: #6a6a6a;
  text-shadow: 0 1px 0 hsla(0, 0%, 100%, 0.8);
}

.smartbanner-kindle .smartbanner-title,
.smartbanner-windows .smartbanner-title {
  color: #4d4d4d;
  font-weight: 700;
}

.smartbanner-kindle .smartbanner-button,
.smartbanner-windows .smartbanner-button {
  padding: 0 10px;
  min-width: 10%;
  color: #6a6a6a;
  background: #efefef;
  background: linear-gradient(180deg, #efefef, #dcdcdc);
  border-radius: 3px;
  box-shadow:
    inset 0 0 0 1px #bfbfbf,
    0 1px 0 hsla(0, 0%, 100%, 0.6),
    inset 0 2px 0 hsla(0, 0%, 100%, 0.7);
}

.smartbanner-kindle .smartbanner-button:active,
.smartbanner-kindle .smartbanner-button:hover,
.smartbanner-windows .smartbanner-button:active,
.smartbanner-windows .smartbanner-button:hover {
  background: #dcdcdc;
  background: linear-gradient(180deg, #dcdcdc, #efefef);
}

.signup {
  color: #fff;
  padding-right: 15px;
  padding-left: 15px;
}

.signup_container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #222629;
  border: 1px #0c0c0d solid;
}

.signup_body {
  padding-right: 10px;
  padding-left: 10px;
}

.infoRow {
  margin: 4px;
  text-align: left;
  font-size: 18px;
  padding-left: 5px;
}

.separator {
  border-bottom: 1px solid grey;
  margin: 0px;
}

.info-container {
  margin: 20px 0 20px;
  width: 100%;
}

.info-row {
  display: flex;
  justify-content: center;
  text-align: left;
}

.container {
  /*height: 100vh;*/
  color: white;
  /*padding: 0px !important;*/
  /*padding-left: 25px;*/
  /*padding-right: 25px;*/
}

.fa-info-icon {
  margin-right: -15px;
  font-size: 15px;
  margin-top: 1px;
}

.info-text {
  margin: 0 auto;
  font-size: 15px;
}

#navbar {
  /* display: none; */
}

.common-text {
  font-size: 15px;
  /*margin:0 ;*/
  /*padding: 0;*/
}

h5 {
  color: #d7a256;
  font-size: 19px;
}

.btn-primary.disabled,
.btn-primary:disabled {
  border-color: #9e9d9c !important;
}

::-webkit-input-placeholder {
  font-size: 14px;
}

.add-devices-form-holder {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.visit-store-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-and-recipient-info {
  max-width: 470px;
  width: 100%;
}

.billing-page-container {
  height: 100%;
  display: flex;
  flex: 1000 1;
  flex-direction: column;
  justify-content: space-evenly;
}

/*for large screen devices*/
@media (min-width: 500px) {
  .billing-page-container {
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .billing-middle-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .added-devices-and-button-container {
    width: 100%;
  }
  .setup-billing-button {
    width: 100%;
  }
}

.success-screen-text {
  text-align: left;
  font-size: 0.85rem;
}

.success-screen-heading {
}

.success-screen-heading h4 {
  color: rgb(214, 161, 87);
  margin: 10px 0 0 0;
}

.hr-tag {
  width: 30%;
  align-self: flex-start;
  border: 1px solid #fafafa;
}

.line {
  height: 1px;
  background-color: #9e9d9c;
  flex: 1 1;
}

.hr-section-holder {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bottom-margin {
  margin: 0 0 10px 0;
}

.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow:
    rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

.bike {
  font-size: 15px;
  font-weight: 500;
}

.logo-holder,
.login-form-logo-holder {
  margin: 15px;
  padding-left: 20px;
  padding-right: 20px;
}

.logo-holder #logo-image,
.login-form-logo-holder #logo-image {
  height: 50%;
  width: 75%;
}

.middle-section-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 10px;
  color: #fafafa;
}

.middle-section-container .response-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fafafa;
  font-size: 22px;
}

.middle-section-container .response-container h4 {
  padding-top: 15px;
}

.middle-section-container .response-container #error-icon {
  margin: 7px;
  color: #dc3545;
}

.middle-section-container .response-container #success-icon {
  margin: 7px;
  color: #097b09;
}

.middle-section-container button {
  margin-top: 25px;
  padding: 10px;
}

ul {
  list-style-type: none;
}

.add-devices-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 100 1;
  align-items: center;
}

.modal-container {
  background: #222629;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modal-content {
  border: 0 !important;
}

.modal-backdrop {
  opacity: 1 !important;
  background-color: #222629;
}

.phone {
  height: 50px;
  width: 100px;
  border: 3px solid white;
  border-radius: 10px;
  animation: rotate 1.5s ease-in-out infinite alternate;
  /* display: none; */
}

.message {
  color: white;
  font-size: 1em;
  margin-top: 40px;
  /* display: none; */
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(-90deg);
  }
}

@media only screen and (max-device-width: 812px) and (orientation: landscape) {
  .phone,
  .message {
    display: block;
  }
}

