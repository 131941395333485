.userOnBoarding_container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  height: 100%;
}

.userOnBoarding_body-container {
  max-width: 470px;
  padding: 0 25px 20px 25px;
  background-color: #222629;
  border-radius: 10px;
  border: 1px #0c0c0d solid;
  /*min-height: 90%;*/
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.userOnBoarding_logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
