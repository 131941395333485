.download_page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
}

.download_page-button {
  margin-bottom: 15px;
}
