.change-profile_edit-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 10px 15px;
  background-color: #d6a157;
  color: black !important;
  border-width: 0.4px;
  border-color: transparent !important;
  font-weight: 600;
  margin-left: 15px;
  margin-bottom: 15px;
}

.change-profile_edit-button:active {
  background-color: #d6a157 !important;
  border-color: transparent !important;
}

.change-profile_edit-button:focus {
  background-color: #d6a157 !important;
  border-color: transparent !important;
}

.change-profile_edit-button:hover {
  background-color: #d6a157 !important;
  border-color: transparent !important;
}

.change-profile_row {
  width: 100%;

  //margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.change-profile_text {
  margin-bottom: 15px;
  text-align: left;
}
