.reset-password {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

.reset-password_container {
  margin-top: 20px;
  background-color: #222629;
  border: 1px #0c0c0d solid;
}

.reset-password_header,
.reset-password_body {
  height: 100%;
  color: #fff;
  padding-right: 10px;
  padding-left: 10px;
}

.reset-password_header {
  background: #4d5359;
}

.reset-password_header-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 6vh;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
}

.reset-password_body-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
