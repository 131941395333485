.spinner-container {
  height: 100%;
  width: 100vw;
  background: #292929;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.spinner-and-text-holder {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.text-container {
  color: white;
  font-size: 35px;
  font-weight: 600;
}
