.signup {
  color: #fff;
  padding-right: 15px;
  padding-left: 15px;
}

.signup_container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #222629;
  border: 1px #0c0c0d solid;
}

.signup_body {
  padding-right: 10px;
  padding-left: 10px;
}

.infoRow {
  margin: 4px;
  text-align: left;
  font-size: 18px;
  padding-left: 5px;
}

.separator {
  border-bottom: 1px solid grey;
  margin: 0px;
}

.info-container {
  margin: 20px 0 20px;
  width: 100%;
}

.info-row {
  display: flex;
  justify-content: center;
  text-align: left;
}

.container {
  /*height: 100vh;*/
  color: white;
  /*padding: 0px !important;*/
  /*padding-left: 25px;*/
  /*padding-right: 25px;*/
}

.fa-info-icon {
  margin-right: -15px;
  font-size: 15px;
  margin-top: 1px;
}

.info-text {
  margin: 0 auto;
  font-size: 15px;
}

#navbar {
  /* display: none; */
}

.common-text {
  font-size: 15px;
  /*margin:0 ;*/
  /*padding: 0;*/
}

h5 {
  color: #d7a256;
  font-size: 19px;
}

.btn-primary.disabled,
.btn-primary:disabled {
  border-color: #9e9d9c !important;
}

::-webkit-input-placeholder {
  font-size: 14px;
}

.add-devices-form-holder {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.visit-store-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-and-recipient-info {
  max-width: 470px;
  width: 100%;
}

.billing-page-container {
  height: 100%;
  display: flex;
  flex: 1000;
  flex-direction: column;
  justify-content: space-evenly;
}

/*for large screen devices*/
@media (min-width: 500px) {
  .billing-page-container {
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .billing-middle-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .added-devices-and-button-container {
    width: 100%;
  }
  .setup-billing-button {
    width: 100%;
  }
}

.success-screen-text {
  text-align: left;
  font-size: 0.85rem;
}

.success-screen-heading {
}

.success-screen-heading h4 {
  color: rgb(214, 161, 87);
  margin: 10px 0 0 0;
}

.hr-tag {
  width: 30%;
  align-self: flex-start;
  border: 1px solid #fafafa;
}

.line {
  height: 1px;
  background-color: #9e9d9c;
  flex: 1;
}

.hr-section-holder {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bottom-margin {
  margin: 0 0 10px 0;
}
