.delete_page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delete_page-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.container_text-box {
  padding-top: 15px;
  padding-right: 15px;
  padding-left: 15px;
  text-align: left;
}

.delete_page-button {
  margin-bottom: 15px;
}
