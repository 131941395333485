html {
  height: 100%;
  /*position: fixed;*/
}

body {
  height: 100%;
  font-family: 'Blender Pro', serif;
  background: #1c1c1e;
  position: fixed;
  --regular-text: 16px;
  --big-text: 1.25rem;
  --small-text: 0.812rem;
  --tiny-text: 0.625rem;
  --small-header: 1.562rem;
  --header: 1.938rem;
  --golden-color: rgba(214, 161, 87, 1);
  --golden-border-color: #b77c2c;
  --whithe-color: #fafafa;
  --input-and-buttons-height: 58px;
}

#root {
  height: 100%;
  line-height: 1.2;
  font-size: 16px;
  font-family: 'Blender Pro', serif;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100vw;
  background: #1c1c1e;
  //background: #222629;
  /*background: #333333; this is for the back theme */

  -webkit-overflow-scrolling: touch;
}

.spinner-grow {
  color: #d7a157 !important;
}

.gold {
  color: #d7a157 !important;
}

.gold-button-variant {
  border-radius: 4px;
  width: 100%;
  background-color: #d6a157;
  color: black !important;
  border-width: 0.4px;
  border-color: transparent !important;
  font-size: 21px;
  line-height: 21px;
  padding: 14px 0px;
  font-family: 'Blender Pro Bold';
  text-transform: uppercase;
}

.gold-button-variant:disabled {
  background-color: #d6a157;
  color: black;
  border-color: transparent !important;
}

.gold-button-variant:active {
  background-color: #d6a157 !important;
  border-color: transparent !important;
}

.gold-button-variant:focus {
  background-color: #d6a157 !important;
  color: black;
  border-color: transparent !important;
}

.gold-button-variant:hover {
  background-color: #d6a157 !important;
  color: black;
  border-color: transparent !important;
}
.black-button-variant {
  background-color: #2d312d !important;
  border-radius: 4px;
  padding: 9px;
  width: 100%;
  color: #ffffff;
  border-width: 0.4px;
  border-color: transparent !important;
  margin-top: 20px;
}

.green-button-variant {
  border-radius: 10px;
  padding: 9px;
  width: 100%;
  border-width: 0.4px;
  border-color: transparent !important;
  margin-top: 5px;
  font-weight: 600;
}

.red-button-variant {
  border-radius: 10px;
  padding: 9px;
  width: 100%;
  background-color: #ee4547 !important;
  color: white !important;
  border-width: 0.4px;
  border-color: transparent !important;
  margin-top: 5px;
  font-weight: 600;
}

.basic-button-variant {
  border-radius: 6px;
  padding: 7px;
  width: 100%;
  border-width: 0.4px;
  border-color: #ced4da !important;
  margin-top: 5px;
  font-weight: 600;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #909;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: red;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: red;
}

.form-input {
  background-color: #161816 !important;
  border-radius: 6px;
  border: 1px solid #2d312d;
  color: #656565;
  height: calc(1.5em + 0.75rem + 6px);
  text-align: left;
}

.form-input:focus {
  color: #c2bdbd;
  border-color: var(--golden-color);
  outline: none !important;
  box-shadow: none;
}
body .form-select {
  background-color: #161816 !important;
  border-radius: 10px;
  border: 1px solid #2d312d;
  color: var(--whithe-color);
  font-size: var(--big-text);
  height: calc(1.5em + 0.75rem + 6px);
  text-align: left;
}

body .form-select:focus {
  color: #c2bdbd;
  border-color: var(--golden-color);
  outline: none !important;
  box-shadow: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  background-color: #f66e62;
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #161816 inset !important;
}

.verify-сode-text {
  text-decoration: underline;
  line-height: 20px;
  font-weight: bolder;
  font-size: 20px;
  font-family: 'Blender Pro Bold';
  color: #fafafa;
}

::placeholder {
  text-align: center;
}

#content {
  /*!*height: 90%;*!*/
  /*flex: 9 1 0;*/
  /*color: #fff;*/
  /*overflow-y: auto;*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

#navbar {
  /*height: 10%;*/
  flex: 1 1 0;
  background: #3a3f42;
}

#error-symbol {
  font-size: 50px;
  border: 1px solid;
  border-radius: 50%;
  height: 55px;
  width: 55px;
  margin: auto;
}

.internet-error {
  display: flex;
  justify-content: space-between;
  height: 35px;
  width: 100%;
  background: #f66e62;
  margin-top: 0;
  font-size: 16px;
}

.internet-error p {
  font-size: 16px;
  line-height: 35px;
  color: #fff;
  margin-left: 6%;
}

.container-settings-form {
  color: white;
  border-radius: 10px;
  border-width: 2px;
  border-color: #161816;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;
}

.container-form {
  color: white;
  flex: 1;
  border-radius: 10px;
  border-width: 2px;
  border-color: #161816;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-self: center;
}

.notification-container {
  flex: 1;
}

.input-container.reset-margin {
  margin-bottom: 0; /* Example: Removing bottom margin */
  padding: 15px; /* Adding padding */
  background-color: #f8f9fa; /* Light background */
}

body .form-control {
  background-color: #131313;
  border: 1px solid #131313;
  border-radius: 10px;
  color: #fafafa;
  font-size: var(--big-text);
  height: var(--input-and-buttons-height);
}

body .form-control:disabled {
  border: 1px solid #131313;
  opacity: 0.4;
}

body .form-control:focus {
  background-color: #131313;
  color: #fafafa;
  box-shadow: 0 0 0 0.1px #d7a157;
  border-color: var(--golden-color);
}

.form-floating > .form-control ~ label {
  color: #979997 !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.form-floating > .form-control:focus ~ label {
  color: #979997 !important;
}

.form-floating {
  height: var(--input-and-buttons-height);
}

.form-floating > input + label::after {
  background: transparent !important;
  color: red !important;
}

.floating-label {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
}

.form-floating > .form-select ~ label::after {
  background: transparent !important;
}

.form-floating > .form-select ~ label {
  color: #979997 !important;
  font-family: 'Blender Pro';
  font-size: var(--regular-text);
}

.select {
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
    no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
}

@media only screen and (max-width: 600px) {
  .internet-error p {
    font-size: 14px;
  }
}

.internet-error div {
  color: #fff;
  margin-right: 8%;
  margin-top: 4px;
  -webkit-animation: spin 1000ms infinite linear;
  animation: spin 1000ms infinite linear;
}

.toast-notification {
  position: absolute !important;
  margin-bottom: 10px !important;
  margin-top: 40px !important;
  width: 470px !important;
}
.toast-notification span {
  width: 100% !important;
  padding: 0px 0px !important;
  border-radius: 10px !important;
}

/* Remove Arrows: Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove Arrows: Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@font-face {
  font-family: 'metropolisbold';
  src:
    url('fonts/metropolis-bold-webfont.woff2') format('woff2'),
    url('fonts/metropolis-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'brandonbold';
  src:
    url('fonts/brandon_bld.woff') format('woff'),
    url('fonts/brandon_bld.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'brandonregular';
  src:
    url('fonts/brandon_med.woff') format('woff'),
    url('fonts/brandon_med.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'metropolisregular';
  src:
    url('fonts/metropolis-regular-webfont.woff2') format('woff2'),
    url('fonts/metropolis-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Blender Pro';
  src: url('fonts/BlenderPro-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Blender Pro Heavy';
  src: url('fonts/BlenderPro-Heavy.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Blender Pro Bold';
  src: url('fonts/BlenderPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

.hr-tag {
  width: 30%;
  align-self: flex-start;
  border: 1px solid #fafafa;
}

.success-button {
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  padding: 8px;
}

.settings-option-text {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  font-size: 25px;
  font-weight: 400;
}

.clickable-link {
  color: #d7a157;
  font-size: var(--big-text);
  text-decoration: none;
}

.clickable-link:hover {
  color: #d7a157;
  text-decoration: none;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.reset-margin {
  margin: 0px;
}

.reset-padding {
  padding: 0px;
}

.uppercase {
  text-transform: uppercase;
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding: 25px;
  box-sizing: border-box;
  width: 470px;
  background-color: rgba(34, 38, 41, 1);
  border-radius: 10px;
}

body .primary-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: var(--input-and-buttons-height);
  gap: 10px;
  width: 100%;
  padding: 14px;
  border: 1.5px solid var(--golden-border-color);
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #d7a157 !important;
  font-family: 'Blender Pro Bold';
  font-size: var(--small-header);
  text-transform: uppercase;
  color: #0c0c0d;
  margin-top: 0 !important;

}

.logo-main {
  maxWidth: 470;
  width: 100%;
}

body .primary-button:hover {
  background-color: #d6a157;
  color: black;
  border: 1.5px solid var(--golden-border-color);

}

body .primary-button:disabled {
  font-family: 'Blender Pro Bold';
  color: #0c0c0d;
}

body .primary-button:active {
  border: none;
  color: #0c0c0d !important;
}

body .primary-button:focus {
  box-shadow: none;
  border: none;
}

body .edit-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: var(--input-and-buttons-height);
  gap: 10px;
  border: 1.5px solid var(--golden-border-color);
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #d7a157 !important;
  font-family: 'Blender Pro Bold';
  font-size: var(--big-text);
  text-transform: uppercase;
  color: #0c0c0d;
  margin-left: 15px;
}

body .edit-button:hover {
  background-color: #d6a157;
  color: black;
  border-color: transparent;
}

body .edit-button:disabled {
  font-family: 'Blender Pro Bold';
  color: #0c0c0d;
}

body .back-button-header-container {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}

.back-button-header-title {
  font-size: var(--header);
  color: var(--golden-color);
  text-transform: uppercase;
}

body .regular-text {
  color: var(--whithe-color);
  font-size: var(--regular-text);
  margin: 0;
  text-align: left;
  padding-left: 5px;
}

body .small-text {
  font-size: var(--small-text);
  color: #6c757d !important;
  margin: 0;
}

body .standart-bottom-padding {
  margin-bottom: 20px;
}

body .screen {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.gapper {
  gap: 20px;
}

.no-gap {
  gap: 0;
}

.floating-label-custom {
  flex: 1;
  font-size: 1.2rem;
}

body .default-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

body .red-variant {
  background-color: #ee4547 !important;
  border: none;
  color: var(--whithe-color);
}

body .red-variant:hover {
  background-color: #ee4547 !important;
  border: none;
  color: var(--whithe-color);
}

body .transparent-variant {
  background-color: transparent !important;
  border: 1px solid #979997;
  color: var(--whithe-color);
}

body .default-margin-top {
  margin-top: 20px;
}

.regular-form {
  display: flex; flex-direction: column; flex: 1; width: 100%; gap: 20px;
}

@supports (-webkit-touch-callout: none) {
  .form-floating > .form-control ~ label::after {
    content: none;
  }

  .form-floating > .form-control:focus ~ label::after,
  .form-floating > .form-control:not(:placeholder-shown) ~ label::after {
    content: ''; /* Adjust content or styles as needed */
  }
}

.floating-label-custom label {
  transform: scale(1) translateY(0); /* Ensures initial position */
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label {
  transform: scale(0.7) translateY(-0.5rem) translateX(0.15rem) !important;
}

@supports (-webkit-touch-callout: none) {
  /* Targeting Safari specifically */

  .floating-label-custom label::after {
    display: none; /* Hides ::after by default in Safari */
  }

  .form-floating > .form-control:focus ~ label::after,
  .form-floating > .form-control:not(:placeholder-shown) ~ label::after {
    display: inline; /* Only show ::after when focused or has value */
  }
}

/* Default state */
.form-floating > .form-control ~ label {
  transform: scale(1) translateY(0) !important; /* Keeps label in place by default */
}

select.testing:focus {
  outline: none;
  box-shadow: none;
  -webkit-appearance: none !important; /* Ensures consistent appearance across browsers */
}

.settings_button_icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}


@media only screen and (max-width: 768px) {

  .container-settings-form, .container-form, .notification-container, .main-container {
    max-width: 90%;
    width: 470px;
  }

  .gold-button-variant, .black-button-variant, .green-button-variant, .red-button-variant, .basic-button-variant {
    font-size: 18px;
    padding: 10px;
  }

  .settings_button_icon {
    width: 28px;
    height: 28px;
  }

  .back-button-header-title {
    font-size: 1.5rem;
  }

  .primary-button, .edit-button {
    height: 50px;
  }
}

@media only screen and (max-width: 600px) {

  body {
    --regular-text: 14px;
    --big-text: 1rem;
    --small-header: 1.25rem;
    --header: 1.5rem;
    --input-and-buttons-height: 50px;
    background-color: red;
  }
  logo-main {
    max-width: 100%;
  }

  .container-settings-form, .container-form, .notification-container, .main-container {
    min-width: 350px;
    width: 90%;
    padding: 10px;

  }

  .gold-button-variant, .black-button-variant, .green-button-variant, .red-button-variant, .basic-button-variant {
    padding: 8px;
    font-size: 16px;
  }

  .settings_button_icon {
    width: 24px;
    height: 24px;
  }

  .internet-error p {
    font-size: 14px;
  }

  .primary-button, .edit-button {
    height: var(--input-and-buttons-height);
    font-size: var(--regular-text);
  }

  .back-button-header-title {
    font-size: var(--small-header);
  }

  .hr-tag {
    width: 50%;
  }

  .toast-notification {
    width: 90% !important;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 480px) {

  .container-settings-form, .container-form, .notification-container, .main-container {
    min-width: 350px;
    width: 90%;
    margin: 10px;
  }

  logo-main {
    width: 10px;
  }

  .gold-button-variant, .black-button-variant, .green-button-variant, .red-button-variant, .basic-button-variant {
    padding: 6px;
    font-size: 14px;
  }

  .settings_button_icon {
    width: 20px;
    height: 20px;
  }

  .primary-button, .edit-button {
    font-size: var(--small-header) !important;
  }

}
