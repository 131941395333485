.settings-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fafafa;
}

.settings-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
}

.settings-page .header {
  color: white;
  font-size: 2em;
  font-weight: bold;
  background-color: #4d5359;
}

.settings-page_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

.settings-page_body {
  width: 100%;
  padding-top: 15px;
  background-color: #222629;
  border-width: 1px;
  border-color: rgba(204, 202, 200, 0.3);
  border-style: solid;
}

.settings-page_header {
  font-size: 2em;
  color: #d7a157;
}

.settings-page_subpage-header {
  font-size: 2em;
  color: #d7a157;
}

.settings-page .info-container {
}

.settings-page .setup-new-device {
  border: 2px solid black;
  border-radius: 4px;
}

.settings-page .button-controls {
  padding: 10px 10px 25px 10px;
}

.settings-page .render-container {
  display: flex;
  flex: 1;
  width: 100%;
}

.settings-page .back-to-all-settings-button {
  display: flex;
  justify-content: center;
}

.settings-page .back-to-all-settings-button .btn {
  width: 50%;
}

.settings-page .form-container {
  /*padding: 15px 15px 15px 15px;*/
  width: 100%;
}

.settings-page .log-out-button-container {
}

.settings-page .header-container {
  display: flex;
  background: #4d5359;
  height: 6vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

.settings-page .header-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

.settings-page .back {
  width: 25px;
  color: #d7a157;
  font-size: 22px;
  background: #222629;
  padding: 5px 5px 5px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.settings_page_buttons {
  border-radius: 10px;
  padding: 0 !important;
  color: #f9f9f9 !important;
  border: 1px solid #2f3237 !important;
  font-weight: 600;
  box-shadow: none;
  display: flex;
  font-family: 'Blender Pro Bold', serif;
  background-color: #2f3237 !important;
  text-decoration: none;
  width: 100%;
}

.settings_page_buttons:hover {
  background-color: #2f3237 !important;
  border: 1px solid #2f3237 !important;
  text-decoration: none;
}

.settings-page .back span {
  margin-left: 5px;
}

.component-header {
  width: 80%;
  font-size: 1.6em;
}

input::placeholder {
  color: #c1c2c3 !important;
}

.form-group {
  color: white;
  text-align: left;
}

body .settings-option-container {
  padding: 13px 15px;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-start;
  background-color: #2f3237;
  border-radius: 6px;
  align-items: center;
}


@media (min-width: 500px) {
  .buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .setting-form {
    text-align: -moz-center;
    text-align: -webkit-center;
  }
  .component-header {
    font-size: 2em;
  }
}

.slide-left-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.slide-left-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

.slide-left-exit {
  opacity: 1;
  transform: translateX(0%);
}

.slide-left-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

.slide-right-enter {
  opacity: 0;
  transform: translateX(100%);
}

.slide-right-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

.slide-right-exit {
  opacity: 1;
  transform: translateX(0%);
}

.slide-right-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

.transition-group {
  position: relative;
}

.route-section {
  position: absolute;
  width: 100%;
}
