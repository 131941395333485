.login-body {
  height: 100%;
  color: #fff;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
}

.login-form-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-container {
  max-width: 470px;
  background-color: #222629;
  border-radius: 10px;
  border: 1px #0c0c0d solid;
}

.forgot-password-buttons-container {
  width: 100%;
}

.header-container {
  display: flex;
  background: #4d5359;
  height: 6vh;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

.back {
  width: 25px;
  color: #d7a157;
  font-size: 22px;
  padding: 5px 5px 5px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.back-button-login-page .svg-inline--fa {
  margin-bottom: -1px;
  margin-right: 4px;
}

.signup-invite {
  color: #fff;
}

.reset-password_form-text {
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
}

@media (min-width: 500px) {
  .container-body-form {
    width: 100% !important;
  }

  .content-container {
    //width: 40%;
  }

  .reset-password,
  .login-form {
    text-align: -moz-center;
    text-align: -webkit-center;
  }

  .login-form-logo-holder img {
    width: 70% !important;
  }
}
